<template>
    <div>
        <confirm-delete
            ref="modal"
            :msg="deleteMsg"
            :title="titleMsg"
            @on-submit="deleteHotelImage"
        />

        <confirm-delete
            ref="deleteReviewModal"
            :msg="deleteReviewMsg"
            :title="titleMsg"
            @on-submit="deleteReview"
        />
        <b-card>
            <b-row>
                <b-col md="6">
                    <b-form-group label="Hotel Id" label-for="hc-hotel-id">
                        <b-form-input
                            v-model="hotelId"
                            class="hotel_id"
                            placeholder="Hotel Id"
                            :clearable="false"
                            :disabled="true"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                        class="md-2"
                        label="Select Languages"
                        label-for="mc-select-language"
                    >
                        <v-select
                            v-model="selectedLanguages"
                            class="language"
                            placeholder="Select Languages"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :clearable="false"
                            :disabled="true"
                            @input="getDescbyLang($event)"
                        />
                    </b-form-group>
                </b-col>
            </b-row>
        </b-card>

        <b-card>
            <b-row>
                <b-col md="6">
                    <b-form-group
                        class="md-2"
                        label="Select Rating"
                        label-for="mc-select-language"
                    >
                        <v-select
                            v-model="reviewRating"
                            class="rating"
                            placeholder="Select Rating"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :clearable="true"
                            :options="rating"
                            @input="changeRating"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                        class="md-2"
                        label="Select Rating"
                        label-for="mc-select-language"
                    >
                        <v-select
                            v-model="pointRating"
                            class="rating"
                            placeholder="Select Rating"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :clearable="true"
                            :disabled="test"
                            :options="decimalRating()"
                        />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row v-if="!reviewRating && !pointRating">
                <b-col md="12">
                    <div class="demo-spacing-0">
                        <b-alert variant="primary" show>
                            <div class="alert-body">
                                <span>You have not rated this hotel!</span>
                            </div>
                        </b-alert>
                    </div>
                </b-col>
            </b-row>
        </b-card>

        <validation-observer ref="reviewFormRules">
            <div class="reviewForm">
            <b-form @submit.prevent>
                <b-card>
                    <b-row>
                        <b-col md="6" style="margin:auto">
                            <b-form-group
                                class="md-2"
                                label="Select Whitelabel"
                                label-for="mc-select-language"
                            >
                                <v-select
                                    v-model="wlCode"
                                    placeholder="Select Whitelabel"
                                    :dir="
                                        $store.state.appConfig.isRTL
                                            ? 'rtl'
                                            : 'ltr'
                                    "
                                    :clearable="false"
                                    :options="whitelabelList"
                                    @input="changeWhiteLabel"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="6" style="margin:auto">
                            <validation-provider
                                name="user"
                            >
                                <b-form-group
                                    class="md-2"
                                    label="Select User"
                                    label-for="mc-select-user"
                                >
                                    <v-select
                                        v-model="addReviewForm.userId"
                                        :options="customerAgentList"
                                        class="user"
                                        placeholder="Select User"
                                        :dir="
                                            $store.state.appConfig.isRTL
                                                ? 'rtl'
                                                : 'ltr'
                                        "
                                        :clearable="true"
                                    />
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                    </b-row>
                </b-card>

                <b-card>
                    <b-row>
                        <b-col md="12">
                            <validation-provider
                                #default="{ errors }"
                                name="review"
                                rules="required|max:1500|min:3"
                            >
                                <b-form-group
                                    class="md-2"
                                    label="Reviews"
                                    label-class="font-weight-bold pt-0"
                                >
                                    <template>
                                        <!-- <quill-editor
                                            v-model="addReviewForm.review"
                                            :options="snowOption"
                                        /> -->
                                        <div>
                                            <b-form-textarea
                                                v-model="addReviewForm.review"
                                                id="textarea-default"
                                                placeholder="Ex: Its an excellent choice for travellers offering a friendly environment."
                                                rows="3"
                                            />
                                        </div>
                                    </template>
                                    <small class="text-danger">{{
                                        errors[0]
                                    }}</small>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                    </b-row>

                    <b-row v-if="!editReviewMode">
                        <b-col md="10"></b-col>
                        <b-col md="2">
                            <b-button
                                :disabled="loadingReviewButton"
                                variant="primary"
                                type="submit"
                                @click="addReview"
                            >
                                Add Review
                                <div
                                    v-if="loadingReviewButton"
                                    class="spinner-border spinner-border-sm"
                                />
                            </b-button>
                        </b-col>
                    </b-row>

                    <b-row v-if="editReviewMode">
                        <b-col md="10"></b-col>
                        <b-col md="2">
                            <b-button
                                :disabled="loadingReviewButton"
                                variant="primary"
                                type="submit"
                                @click="editReview"
                            >
                                Edit Review
                                <div
                                    v-if="loadingReviewButton"
                                    class="spinner-border spinner-border-sm"
                                />
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card>
            </b-form>
            </div>
        </validation-observer>

        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col
                        cols="12"
                        md="6"
                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                    >
                        <label>Show</label>
                        <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                        />
                        <label>entries</label>
                    </b-col>
                </b-row>
            </div>

            <b-table
                ref="refReviewListTable"
                class="position-relative"
                :items="reviews"
                responsive
                :fields="tableColumns"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No matching records found"
                :sort-desc.sync="isSortDirDesc"
            >
                <template #cell(sr_no)="data">
                    {{ data.index + 1 }}
                </template>

                <template #cell(firstname)="data">
                    {{ data.item.firstName }}
                </template>

                <template #cell(lastname)="data">
                    {{ data.item.lastName }}
                </template>
                <template #cell(reviews)="data">
                    <div
                        class="text-break"
                    >
                        <!--eslint-disable-next-line vue/no-v-html -->
                        <span
                            v-if="!data.item.isRead"
                            v-html="data.item.review.slice(0, 70)"
                        /><br>
                        <router-link
                            v-if="
                                !data.item.isRead &&
                                    data.item.review.length >= 50
                            "
                            to=""
                            @click.native="activateReadMore(data.item.id)"
                            ><b>...Read more</b></router-link
                        >
                        <!--eslint-disable-next-line vue/no-v-html -->
                        <span
                            v-if="data.item.isRead"
                            v-html="data.item.review"
                        />

                        <router-link
                            v-if="
                                data.item.isRead &&
                                    data.item.review.length >= 50
                            "
                            to=""
                            @click.native="activateReadLess(data.item.id)"
                        >
                            <b>&nbsp;&nbsp;Read less</b>
                        </router-link>
                    </div>
                </template>
                <template #cell(white_label)="data">
                    {{ data.item.whitelabelName }}
                </template>

                <template #cell(actions)="data">
                    <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                    >
                        <template #button-content>
                            <feather-icon
                                icon="MoreVerticalIcon"
                                size="16"
                                class="align-middle text-body"
                            />
                        </template>
                        <b-dropdown-item @click="getReviewById(data.item.id)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>

                        <b-dropdown-item
                            @click="deleteReviewModal(data.item.id)"
                        >
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                        <span class="text-muted"
                            >Showing
                            {{
                                totalReviews >= 1 ? startIndex + 1 : startIndex
                            }}
                            to
                            {{
                                endIndex > totalReviews
                                    ? totalReviews
                                    : endIndex
                            }}
                            of {{ totalReviews }} entries</span
                        >
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalReviews"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item"
                        >
                            <template #prev-text>
                                <feather-icon
                                    icon="ChevronLeftIcon"
                                    size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                    icon="ChevronRightIcon"
                                    size="18"
                                />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>

        <!-- form -->
        <validation-observer ref="simpleRules">
            <b-form @submit.prevent="$emit('on-Submit', hotelContentEditForm)">
                <app-collapse
                    v-for="(lang, index) in languageArr"
                    :key="index"
                    visible
                    :type="collapseType"
                >
                    <app-collapse-item
                        v-if="
                            hotelContentEditForm.contents[index] &&
                                hotelContentEditForm.contents[index].description
                        "
                        :is-visible="isVisible"
                        :title="`${languageArr[index].name}`"
                    >
                        <b-row>
                            <b-col cols="12" md="12">
                                <b-form-group
                                    label="Hotel Name"
                                    label-for="mc-hotel-name"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Hotel Name"
                                        :rules="languageArr[index].iso_1Code == 'RO' ? '' :  'required'"
                                    >
                                        <b-form-input
                                            v-model="
                                                hotelContentEditForm.contents[
                                                    index
                                                ].hotelName
                                            "
                                            class="hotel_name"
                                            :state="
                                                errors.length > 0 ? false : null
                                            "
                                            placeholder="Hotel Name"
                                        />
                                        <small class="text-danger">{{
                                            errors[0]
                                        }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row
                            v-for="(desc, descIndex) in hotelContentEditForm
                                .contents[index].description"
                            :key="descIndex"
                        >
                            <b-col cols="12" md="12">
                                <b-form-group
                                    :label="`Hotel ${desc.Type}`"
                                    :label-for="`mc-hotel-${desc.Type}`"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        :name="`Hotel ${desc.Type}`"
                                        :rules="languageArr[index].iso_1Code == 'RO' ? '' :  'required'"
                                    >
                                        <template>
                                            <quill-editor
                                                v-if="
                                                    hotelContentEditForm
                                                        .contents[index]
                                                        .description[descIndex]
                                                        .Type !== 'HeadLine'
                                                "
                                                v-model="
                                                    hotelContentEditForm
                                                        .contents[index]
                                                        .description[descIndex]
                                                        .Text
                                                "
                                                :state="
                                                    errors.length > 0
                                                        ? false
                                                        : null
                                                "
                                                :options="snowOption"
                                            />

                                            <div
                                                v-if="
                                                    hotelContentEditForm
                                                        .contents[index]
                                                        .description[descIndex]
                                                        .Type === 'HeadLine'
                                                "
                                            >
                                                <b-form-textarea
                                                    v-model="
                                                        hotelContentEditForm
                                                            .contents[index]
                                                            .description[
                                                            descIndex
                                                        ].Text
                                                    "
                                                    rows="3"
                                                    placeholder="Hotel Headline"
                                                />
                                            </div>

                                            <small class="text-danger">{{
                                                errors[0]
                                            }}</small>
                                        </template>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </app-collapse-item>
                </app-collapse>
                <br />
                <b-card-code>
                    <b-row col>
                        <b-col md="12">
                            <b-form-group
                                label="Hotel Images"
                                label-for="mc-hotel-images"
                            >
                                <b-carousel
                                    v-if="
                                        hotelContentEditForm.images.length > 0
                                    "
                                    id="mainCarousel"
                                    controls
                                    indicators
                                    :interval="4000"
                                >
                                    <b-carousel-slide
                                        v-for="(item,
                                        index) in hotelContentEditForm.images"
                                        :key="index"
                                    >
                                        <template v-slot:img>
                                            <b-row>
                                                <b-col
                                                    v-for="(imgsrc,
                                                    ind) in item"
                                                    :key="ind"
                                                    cols="3"
                                                    md="3"
                                                >
                                                    <div
                                                        class="card theme-card theme_card_box"
                                                    >
                                                    <feather-icon
                                                        icon="CopyIcon"
                                                        stroke="white"
                                                        size="28"
                                                        class="feather-icon"
                                                        @click="copyImageUrl(imgsrc)"
                                                    />    
                                                        <img
                                                            :src="imgsrc"
                                                            alt=""
                                                        />
                                                        <div
                                                            class="deleteDiv bg-danger"
                                                            variant="link"
                                                            no-caret
                                                            :right="
                                                                $store.state
                                                                    .appConfig
                                                                    .isRTL
                                                            "
                                                        >
                                                            <feather-icon
                                                                icon="Trash2Icon"
                                                                stroke="white"
                                                                size="18"
                                                                class="align-middle text-body"
                                                                @click="
                                                                    deleteModal(
                                                                        imgsrc
                                                                    )
                                                                "
                                                            />
                                                        </div>
                                                    </div>
                                                </b-col>
                                            </b-row>
                                        </template>
                                    </b-carousel-slide>
                                </b-carousel>
                            </b-form-group>
                        </b-col>

                        <b-col md="7" class="mt-2">
                            <div class="imageBox">
                                <div class="helper" />
                                <div
                                    class="drop align-center"
                                    @dragover.prevent
                                    @drop="onDrop"
                                >
                                    <div class="helper" />
                                    <label
                                        v-if="!imagePreview && !uploadImage"
                                        class="btn btn-primary"
                                    >
                                        SELECT AN IMAGE
                                        <input
                                            type="file"
                                            accept="image/*"
                                            name="image"
                                            @change="onChange"
                                        />
                                    </label>
                                    <div
                                        v-if="imagePreview"
                                        class=" display-inline align-center"
                                        :class="{ image: true }"
                                    >
                                        <img
                                            :src="imagePreview"
                                            alt="Image"
                                            class="img mt-2"
                                        />
                                        <br />
                                        <br />
                                        <button
                                            :disabled="loadingUpload"
                                            class="btn btn-primary mb-2"
                                            @click="addHotelImage"
                                        >
                                            UPLOAD
                                            <div
                                                v-if="loadingUpload"
                                                class="spinner-border spinner-border-sm"
                                            /></button
                                        >&nbsp;&nbsp;
                                        <button
                                            class="btn btn-danger mb-2"
                                            @click="removeFile"
                                        >
                                            REMOVE
                                        </button>
                                    </div>
                                </div>
                                <b-alert class="mt-2" variant="primary" show>
                                    <div class="alert-body">
                                        <span
                                            >Allowed JPG, JPEG, GIF or PNG. Max
                                            size of 1MB.</span
                                        >
                                    </div>
                                </b-alert>
                            </div>
                        </b-col>

                        <b-col md="5" class="mt-2">                            
                            <label for="hotel_hero_image">Update Hero Image</label>
                            <b-input-group>
                                <b-form-input
                                    v-model="heroImageUrl"
                                    class="hotel_hero_image"
                                    placeholder="Hotel Hero Image URL"
                                />
                                <b-input-group-append>
                                    <b-button
                                      variant="outline-primary"
                                      title="Update Image"
                                      @click="updateHotelHeroImage()"
                                      :disabled="!heroImageUrl">
                                        <feather-icon
                                            icon="UploadIcon"
                                            size="16"
                                        />
                                    </b-button>
                                </b-input-group-append>
                                <img class="img mt-1 w-100" :src="heroImageMain" alt="hero image">
                            </b-input-group>
                        </b-col>

                        <!-- submit button -->
                        <b-col md="12" class="demo-inline-spacing">
                            <b-button
                                :disabled="loading"
                                variant="primary"
                                type="submit"
                                @click="hotelContentUpdate"
                            >
                                Update
                                <div
                                    v-if="loading"
                                    class="spinner-border spinner-border-sm"
                                />
                            </b-button>
                            <b-button
                                variant="secondary"
                                :to="{
                                    name:
                                        checkLoginRole() ===
                                            RoleEnum.SuperAdmin ||
                                        checkAccess.view
                                            ? 'hotel-content-list'
                                            : 'dashboard',
                                    query: {
                                        page: this.$route.query.page,
                                        rows: this.$route.query.rows,
                                    },
                                }"
                            >
                                Cancel
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-code>
            </b-form>
        </validation-observer>
    </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import BCardCode from "@core/components/b-card-code";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,
    BAlert,
    BCarousel,
    BCarouselSlide,
    BFormTextarea,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BInputGroup,
    BInputGroupAppend
} from "bootstrap-vue";
import {
    resConditionCheck,
    RoleEnum,
    deleteConfirmMsg,
    ImageError,
    rating,
    decimalRating,
    perPage,
    perPageOptions,
    currentPage,
} from "@/helpers/constant";
import { required } from "@core/utils/validations/validations";
import vSelect from "vue-select";
import errorResponseHandler from "@/services/errorHandler";
import checkLoginRole from "@/helpers/checkLoginRole";
import accessRightCheck from "@/helpers/accessRightCheck";
import languageService from "@/services/language/language.service";
import HotelContentService from "@/services/hotel-content/hotelContent.service";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import ConfirmDelete from "../../confirm-delete/ConfirmDelete.vue";
import whitelabelService from "@/services/whitelabel/whitelabel.service";
import customerAdminService from "@/services/customer-admin/customerAdmin.service";

export default {
    components: {
        BCardCode,
        ValidationProvider,
        ValidationObserver,
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BButton,
        vSelect,
        BCard,
        quillEditor,
        BAlert,
        AppCollapse,
        AppCollapseItem,
        BCarousel,
        BCarouselSlide,
        ConfirmDelete,
        BFormTextarea,
        BTable,
        BPagination,
        BDropdown,
        BDropdownItem,
        BInputGroup,
        BInputGroupAppend
    },
    data() {
        return {
            hotelContentEditForm: {
                contents: [],
                images: [],
            },

            addReviewForm: {
                review: "",
                userId: "",
                hotel_id: "",
            },

            editReviewForm: {
                review: "",
                userId: "",
                hotel_id: "",
            },
            heroImageUrl: '',
            hotelImages: [],
            uploadImage: "",
            staticObject: {
                lang: "",
                hotelName: "",
                description: [],
            },
            defaultDescription: [
                { Type: "HeadLine", Text: "" },
                { Type: "Description", Text: "" },
            ],
            contentData: {},
            languageArr: [],
            selectedLanguages: [],
            required,
            checkAccess: {},
            loading: false,
            loadingUpload: false,
            checkLoginRole,
            rating,
            decimalRating,
            hotelId: this.$route.params.id ? this.$route.params.id : "",
            titleMsg: "",
            deleteMsg: "",
            deleteImageURL: {
                image_url: "",
            },
            snowOption: {
                modules: {
                    toolbar: [
                        [
                            "bold",
                            "italic",
                            "underline",
                            "strike",
                            "align",
                            "link",
                        ],
                        [{ list: "ordered" }, { list: "bullet" }],
                    ],
                },
            },
            RoleEnum,
            selectedFile: null,
            staticLink: "",
            imagePreview: "",
            file: "",
            collapseType: "margin",
            isVisible: true,
            reviewRating: "",
            pointRating: "",
            whitelabelList: [],
            wlCode: "",
            customerAgentList: [],
            tableColumns: [
                { key: "white_label" },
                { key: "firstname" },
                { key: "lastname" },
                { key: "reviews" },
                { key: "actions" },
            ],
            reviews: [],
            loadingReviewButton: false,
            currentPage,
            perPage,
            perPageOptions,
            totalReviews: 0,
            sortBy: "",
            isSortDirDesc: true,
            startIndex: 0,
            endIndex: 0,
            reviewDetails: "",
            editReviewMode: false,
            deleteReviewMsg: "",
            deleteReviewId: "",
            validReviewForm: "",
            heroImageMain: "",
        };
    },

    computed: {
        test() {
            return (this.reviewRating && this.reviewRating.value === 10) ||
                !this.reviewRating
                ? true
                : false;
        },
    },
    watch: {
        currentPage: {
            handler() {
                this.getHotelReviews();
            },
        },

        perPage: {
            handler() {
                this.currentPage = 1;
                this.getHotelReviews();
            },
        },

        sortBy: {
            handler() {
                this.getHotelReviews();
            },
        },

        isSortDirDesc: {
            handler() {
                this.getHotelReviews();
            },
        },
    },
    async created() {
        this.checkAccess = accessRightCheck(this.$route.meta.module.name);
        await this.getLanguageDropdown();
        if (this.$route.params.id) {
            await this.getHotelContent();
        }
        await this.getWhitelabelList();
        await this.getCustomerAgentList(this.wlCode);
        await this.getHotelReviews();
    },
    methods: {
        changeRating() {
            if (this.reviewRating === null || this.reviewRating.value === 10) {
                this.pointRating = "";
            }
        },
        async getHotelContent() {
            const payload = {
                unique_id: this.hotelId,
            };

            await HotelContentService.getHotelContentById(payload)
                .then((res) => {
                    if (resConditionCheck(res.status) && res.data) {
                        this.addReviewForm.hotel_id = res.data.id;
                        console.log("RESPONSE", res.data);
                        this.reviewRating = res.data.customerRating
                            ? {
                                  label: +res.data.customerRating
                                      .toString()
                                      .split(".")[0],
                                  value: +res.data?.customerRating
                                      .toString()
                                      .split(".")[0],
                              }
                            : "";
                        this.pointRating = res.data.customerRating
                            ? Number(
                                  "0." +
                                      res.data?.customerRating
                                          .toString()
                                          .split(".")[1]
                              )
                            : "";
                        this.heroImageMain = res.data?.heroImageMain;
                        this.contentData = res.data.content;
                        this.hotelImages = res.data.images;
                        this.hotelContentEditForm.images = this.filterHotelImages(
                            this.hotelImages
                        );

                        for (let i = 0; i < this.languageArr.length; i += 1) {
                            const index = this.contentData.findIndex(
                                (content) =>
                                    content.lang ===
                                    this.languageArr[i].iso_1Code.toLowerCase()
                            );
                            let desc;
                            if (
                                this.contentData[index]?.description &&
                                this.contentData[index]?.description !== "" &&
                                this.contentData[index]?.description.length > 0
                            ) {
                                desc = this.contentData[index].description.map(
                                    (e) => {
                                        if (
                                            e.Type !== "HeadLine" &&
                                            e.Type !== "Description"
                                        ) {
                                            return this.defaultDescription;
                                        }
                                        return {
                                            Type: e.Type,
                                            Text: e.Text,
                                        };
                                    }
                                );
                            } else {
                                desc = this.defaultDescription;
                            }

                            this.hotelContentEditForm.contents.push({
                                ...this.staticObject,
                                lang: this.languageArr[
                                    i
                                ].iso_1Code.toLowerCase(),
                                hotelName: this.contentData[index]?.hotelName
                                    ? this.contentData[index]?.hotelName
                                    : "",
                                description: desc.length < 2 ? desc[0] : desc,
                            });
                        }
                    }
                })
                .catch((error) => {
                    console.log("error", error);
                    const errorData = errorResponseHandler(error);
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: errorData,
                            icon: "X-CircleIcon",
                            variant: "danger",
                        },
                    });
                });
        },

        async getCustomerAgentList(wl_code) {
            try {
                const res = await customerAdminService.getCustomerAndAgentListing(
                    wl_code.value
                );
                console.log("res", res);
                if (resConditionCheck(res.status) && res.data) {
                    this.customerAgentList = res.data[0].map((e) => ({
                        label: e.email,
                        value: e.userId,
                    }));
                    console.log("xxxxx", this.customerAgentList);
                }
            } catch (error) {
                console.log("error", error);
            }
        },

        async editReview() {
            this.loadingReviewButton = true;
            try {
                const payload = {
                    hotel_id: this.addReviewForm.hotel_id,
                    userId: this.addReviewForm.userId?.value,
                    review: this.addReviewForm.review,
                };
                console.log("payload", payload);
                const res = await HotelContentService.editReview(
                    this.reviewDetails.id,
                    payload,
                    this.wlCode.value
                );
                if (resConditionCheck(res.status) && res.data.data) {
                    setTimeout(() => {
                        this.loadingReviewButton = false;
                        this.editReviewMode = false;
                    }, 3000);
                    this.resetReviewForm();
                    this.$refs.reviewFormRules.reset();
                    await this.getHotelReviews();
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: res.data.message,
                            icon: "X-CircleIcon",
                            variant: "success",
                        },
                    });
                }
            } catch (error) {
                setTimeout(() => {
                    this.loadingReviewButton = false;
                    this.editReviewMode = false;
                }, 3000);
                this.addReviewForm.userId = "";
                this.addReviewForm.review = "";
                console.log("error", error);
                const errorData = errorResponseHandler(error);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: errorData,
                        icon: "X-CircleIcon",
                        variant: "danger",
                    },
                });
            }
        },

        async addReview() {
            let valid = await this.reviewFormValidation();
            if (valid) {
                try {
                    console.log("add review form", this.addReviewForm);
                    const payload = {
                        hotel_id: this.addReviewForm.hotel_id,
                        review: this.addReviewForm.review,
                        userId: this.addReviewForm.userId.value,
                    };
                    this.loadingReviewButton = true;
                    const res = await HotelContentService.addHotelReview(
                        payload,
                        this.wlCode.value
                    );
                    if (resConditionCheck(res.status) && res.data.data) {
                        setTimeout(() => {
                            this.loadingReviewButton = false;
                        }, 3000);
                        this.resetReviewForm();
                        this.$refs.reviewFormRules.reset();

                        await this.getHotelReviews();
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: res.data.message,
                                icon: "X-CircleIcon",
                                variant: "success",
                            },
                        });
                    }
                } catch (error) {
                    console.log("error", error);
                    setTimeout(() => {
                        this.loadingReviewButton = false;
                    }, 3000);
                    const errorData = errorResponseHandler(error);
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: errorData,
                            icon: "X-CircleIcon",
                            variant: "danger",
                        },
                    });
                }
            }
        },

        resetReviewForm() {
            this.addReviewForm.userId = "";
            this.addReviewForm.review = "";
        },

        async getHotelReviews() {
            const payload = {
                no_of_result: this.perPage,
                page: this.currentPage,
                order: this.isSortDirDesc === true ? "DESC" : "ASC",
                hotel_id: this.addReviewForm.hotel_id,
            };
            console.log("payload", payload);
            try {
                const res = await HotelContentService.getHotelReviews(payload);
                if (resConditionCheck(res.status) && res.data.reviews) {
                    this.reviews = res.data.reviews.map((e) => ({
                        ...e,
                        isRead: false,
                    }));
                    this.totalReviews = res.data.count;
                    this.startIndex =
                        this.currentPage * this.perPage - this.perPage;
                    this.endIndex = this.startIndex + this.perPage;
                    console.log("Reviews", this.reviews);
                }
            } catch (error) {
                console.log("error", error);
            }
        },

        activateReadMore(id) {
            const review = this.reviews.find((e) => e.id === id);
            review.isRead = true;
        },

        activateReadLess(id) {
            const review = this.reviews.find((e) => e.id === id);
            review.isRead = false;
        },
        async getReviewById(id) {
            try {
                const res = await HotelContentService.getReviewById(id);
                if (resConditionCheck(res.status) && res.data.data) {
                    console.log("Response", res);
                    this.reviewDetails = res.data.data;
                    this.editReviewMode = true;
                    this.addReviewForm.review = res.data.data.review;
                    this.addReviewForm.userId = {
                        label: res.data.data.email,
                        value: res.data.data.userId,
                    };
                    this.addReviewForm.hotel_id = res.data.data.hotel.id;
                    this.wlCode = {
                        label: res.data.data.whitelabelName,
                        value: res.data.data.wlCode,
                    };
                    console.log("add review form", this.addReviewForm);
                }
            } catch (error) {
                this.editReviewMode = false;
                console.log("error", error);
                const errorData = errorResponseHandler(error);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: errorData,
                        icon: "X-CircleIcon",
                        variant: "danger",
                    },
                });
            }
            const el = this.$el.getElementsByClassName("reviewForm")[0];
            el.scrollIntoView({ behavior: "smooth" });
        },

        deleteReviewModal(id) {
            this.titleMsg = deleteConfirmMsg;
            this.deleteReviewMsg =
                "Are you sure you want to delete this review?";
            this.deleteReviewId = id;
            this.$refs.deleteReviewModal.show();
        },

        async deleteReview(bvModalEvt) {
            if (this.deleteReviewId) {
                bvModalEvt.preventDefault();
                this.$refs.deleteReviewModal.loading = true;
                try {
                    const res = await HotelContentService.deleteReview(
                        this.deleteReviewId
                    );
                    if (resConditionCheck(res.status) && res.data.message) {
                        this.$refs.deleteReviewModal.loading = false;
                        this.$refs.deleteReviewModal.toggleModal = false;
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: res.data.message,
                                icon: "X-CircleIcon",
                                variant: "success",
                            },
                        });
                        this.reviews = this.reviews.filter(
                            (e) => e.id !== this.deleteReviewId
                        );
                        if (
                            this.reviews.length === 0 &&
                            this.currentPage !== 1
                        ) {
                            this.currentPage -= 1;
                            this.getHotelReviews();
                        } else {
                            this.getHotelReviews();
                        }
                    }
                } catch (error) {
                    this.$refs.deleteReviewModal.loading = false;
                    this.$refs.deleteReviewModal.toggleModal = false;
                    const errorData = errorResponseHandler(error);
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: errorData,
                            icon: "X-CircleIcon",
                            variant: "danger",
                        },
                    });
                }
            }
        },

        async getWhitelabelList() {
            this.whitelabelList.push({
                label: "Beontrips",
                value: 1,
            });
            this.wlCode = {
                label: "Beontrips",
                value: 1,
            };
            this.isLoaded = false;
            const payload = {
                no_of_result: 0,
                page: 1,
                order: "ASC",
                order_by: "name",
            };
            await whitelabelService
                .getWhitelableList(payload)
                .then((res) => {
                    if (resConditionCheck(res.status) && res.data.data) {
                        this.isLoaded = true;
                        this.whitelabelList = res.data.data.map((item) => {
                            // if(!item.isDeleted) {
                            return {
                                label: item.name,
                                value: item.id,
                            };
                            // }
                        });
                    }
                })
                .catch((error) => {
                    const errorData = errorResponseHandler(error);
                    // this.$toast({
                    //     component: ToastificationContent,
                    //     props: {
                    //         title: errorData,
                    //         icon: "X-CircleIcon",
                    //         variant: "danger",
                    //     },
                    // });
                });
        },

        filterHotelImages(images) {
            const results = [];
            const imagesArr = images.map((e) => e.main_url);

            let idx = 0;
            // eslint-disable-next-line no-unused-vars
            imagesArr.forEach((e) => {
                const imgs = imagesArr.slice(idx, idx + 4);

                if (imgs.length > 0) {
                    results.push(imagesArr.slice(idx, idx + 4));
                    idx += 4;
                }
            });
            return results;
        },

        async validationForm() {
            let valid = null;
            await this.$refs.simpleRules.validate().then((success) => {
                if (success) {
                    valid = true;
                } else {
                    valid = false;
                }
            });
            return valid;
        },

        async reviewFormValidation() {
            console.log("Validatetriggered");
            await this.$refs.reviewFormRules.validate().then((success) => {
                if (success) {
                    this.validReviewForm = true;
                } else {
                    this.validReviewForm = false;
                }
            });
            return this.validReviewForm;
        },
        async getLanguageDropdown() {
            try {
                const res = await languageService.getLanguageDropdown();
                if (resConditionCheck(res.status) && res.data.data) {
                    this.selectedLanguages = res.data.data.map((e) => ({
                        label: e.name,
                        value: e.id,
                    }));
                    this.languageArr = res.data.data;
                }
            } catch (error) {
                const errorData = errorResponseHandler(error);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: errorData,
                        icon: "X-CircleIcon",
                        variant: "danger",
                    },
                });
            }
        },

        async hotelContentUpdate() {
            this.$refs.simpleRules.validate().then((success) => {
                if (success) {
                    this.loading = true;
                    const requestData = {
                        contents: [],
                    };
                    for (
                        let i = 0;
                        i < this.hotelContentEditForm.contents.length;
                        i += 1
                    ) {
                        const headlines = [];
                        const descriptions = [];
                        for (
                            let j = 0;
                            j <
                            this.hotelContentEditForm.contents[i].description
                                .length;
                            j += 1
                        ) {
                            if (
                                this.hotelContentEditForm.contents[i]
                                    .description[j].Type === "HeadLine"
                            ) {
                                headlines[
                                    this.hotelContentEditForm.contents[i].lang
                                ] = this.hotelContentEditForm.contents[
                                    i
                                ].description[j].Text;
                            }
                            if (
                                this.hotelContentEditForm.contents[i]
                                    .description[j].Type === "Description"
                            ) {
                                descriptions[
                                    this.hotelContentEditForm.contents[i].lang
                                ] = this.hotelContentEditForm.contents[
                                    i
                                ].description[j].Text;
                            }
                        }
                        if (this.reviewRating) {
                            requestData.contents.push({
                                language_code: this.hotelContentEditForm
                                    .contents[i].lang,
                                hotel_name: this.hotelContentEditForm.contents[
                                    i
                                ].hotelName,
                                hotel_headline:
                                    headlines[
                                        this.hotelContentEditForm.contents[i]
                                            .lang
                                    ],
                                hotel_description:
                                    descriptions[
                                        this.hotelContentEditForm.contents[i]
                                            .lang
                                    ],
                                customer_rating: this.reviewRating?.value +
                                      (this.pointRating ? this.pointRating : "")
                            });
                        } else {
                            requestData.contents.push({
                                language_code: this.hotelContentEditForm
                                    .contents[i].lang,
                                hotel_name: this.hotelContentEditForm.contents[
                                    i
                                ].hotelName,
                                hotel_headline:
                                    headlines[
                                        this.hotelContentEditForm.contents[i]
                                            .lang
                                    ],
                                hotel_description:
                                    descriptions[
                                        this.hotelContentEditForm.contents[i]
                                            .lang
                                    ],
                            });
                        }
                    }

                    HotelContentService.updateHotelContent(
                        this.hotelId,
                        requestData
                    )
                        .then((res) => {
                            this.loading = false;
                            this.$router.push({
                                path: "/hotel-content/list",
                                query: {
                                    page: this.$route.query.page,
                                    rows: this.$route.query.rows,
                                },
                            });
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: res.data.message,
                                    icon: "X-CircleIcon",
                                    variant: "success",
                                },
                            });
                        })
                        .catch((error) => {
                            console.log("error", error);
                            this.loading = false;
                            const errorData = errorResponseHandler(error);
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: errorData,
                                    icon: "X-CircleIcon",
                                    variant: "danger",
                                },
                            });
                        });
                }
            });
        },

        deleteModal(id) {
            this.titleMsg = deleteConfirmMsg;
            this.deleteMsg = `Are you sure you want to delete this image?`;
            this.deleteImageURL.image_url = id;
            this.$refs.modal.show();
        },

        async deleteHotelImage(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.$refs.modal.loading = true;
            if (this.deleteImageURL.image_url) {
                await HotelContentService.deleteHotelImage(
                    this.hotelId,
                    this.deleteImageURL
                )
                    .then((res) => {
                        if (resConditionCheck(res.status)) {
                            this.$refs.modal.loading = false;
                            this.$refs.modal.toggleModal = false;
                            this.hotelImages = this.hotelImages.filter(
                                (item) =>
                                    item.main_url !==
                                    this.deleteImageURL.image_url
                            );
                            this.hotelContentEditForm.images = this.filterHotelImages(
                                this.hotelImages
                            );
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: res.data.message,
                                    icon: "CheckIcon",
                                    variant: "success",
                                },
                            });
                        }
                    })
                    .catch((error) => {
                        this.$refs.modal.loading = false;
                        this.$refs.modal.toggleModal = false;
                        const errorData = errorResponseHandler(error);
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: errorData,
                                icon: "X-CircleIcon",
                                variant: "danger",
                            },
                        });
                    });
            }
        },

        async addHotelImage() {
            if (!this.uploadImage) {
                this.loadingUpload = true;
                setTimeout(() => {
                    this.loadingUpload = false;
                }, 3000);

                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: ImageError.Theme_Image_Error,
                        icon: "X-CircleIcon",
                        variant: "danger",
                    },
                });
            }

            const formdata = new FormData();
            const randomName = new Date().getTime() + this.uploadImage.name;
            formdata.append("hotel_image", this.uploadImage, randomName);
            this.loadingUpload = true;
            await HotelContentService.addHotelImage(this.hotelId, formdata)
                .then((res) => {
                    this.loadingUpload = false;
                    if (resConditionCheck(res.status) && res.data.data) {
                        if (res.data.data.Location) {
                            this.hotelImages.unshift({
                                main_url: res.data.data.Location,
                            });
                            this.hotelContentEditForm.images = this.filterHotelImages(
                                this.hotelImages
                            );
                        }
                        this.imagePreview = "";
                        this.uploadImage = "";
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: res.data.message,
                                icon: "CheckIcon",
                                variant: "success",
                            },
                        });
                    }
                })
                .catch((error) => {
                    this.loadingUpload = false;
                    const errorData = errorResponseHandler(error);
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: errorData,
                            icon: "X-CircleIcon",
                            variant: "danger",
                        },
                    });
                });
        },

        onDrop(e) {
            e.stopPropagation();
            e.preventDefault();
            const { files } = e.dataTransfer;
            this.createFile(files[0]);
        },

        onChange(e) {
            const { files } = e.target;
            this.createFile(files[0]);
        },

        createFile(file) {
            if (
                (file.type === "image/jpeg" ||
                    file.type === "image/jpg" ||
                    file.type === "image/png" ||
                    file.type === "image/gif") &&
                file.size <= 1048576
            ) {
                this.uploadImage = file;
                this.imagePreview = URL.createObjectURL(file, file.name);
            } else {
                this.uploadImage = null;
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title:
                            "Only JPG, JPEG or PNG Allowed with Max Size Of 1 MB",
                        icon: "X-CircleIcon",
                        variant: "danger",
                    },
                });
            }
        },

        async changeWhiteLabel() {
            this.addReviewForm.userId = "";
            await this.getCustomerAgentList(this.wlCode);
        },

        removeFile() {
            if (this.uploadImage) {
                this.uploadImage = "";
                this.imagePreview = "";
            } else {
                this.imagePreview = "";
            }
        },

        async updateHotelHeroImage() {
            if (this.heroImageUrl === undefined || this.heroImageUrl === null || this.heroImageUrl.length === 0 ||this.heroImageUrl === '') {
                this.loading = false;
                const errorData = errorResponseHandler({response: {data:{ message: 'Please enter valid URL' } } });
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: errorData,
                        icon: "X-CircleIcon",
                        variant: "danger",
                    },
                });
            }
            const pattern = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
            const valid = pattern.test(this.heroImageUrl);
            if(valid){
                this.loading = true;
            const payload = {
                hero_image_url: this.heroImageUrl
            };

            HotelContentService.updateHotelHeroImage(
                this.hotelId,
                payload,
            )
            .then((res) => {
                this.loading = false;
                this.heroImageMain = this.heroImageUrl;
                this.heroImageUrl = '';
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: res.data.message,
                        icon: "X-CircleIcon",
                        variant: "success",
                    },
                });
            })
            .catch((error) => {
                console.log("error", error);
                this.loading = false;
                const errorData = errorResponseHandler(error);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: errorData,
                        icon: "X-CircleIcon",
                        variant: "danger",
                    },
                });
            });
            }else {
                this.loading = false;
                const errorData = errorResponseHandler({response: {data:{ message: 'Please enter valid URL' } } });
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: errorData,
                        icon: "X-CircleIcon",
                        variant: "danger",
                    },
                });
            }
        },
        copyImageUrl(imgsrc) {
            navigator.clipboard.writeText(imgsrc);
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Image URL Copied",
                    icon: "X-CircleIcon",
                    variant: "success",
                },
            });
        }
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style>
.ql-editor {
    min-height: 150px;
    max-height: 350px;
}
</style>

<style lang="scss" scoped>
.theme-card {
    background: #fff;
    border-radius: 2px;
    display: inline-block;
    height: 180px;
    position: relative;
    width: 100%;
    margin: 0 0 30px 0;
}
.theme-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
	transition: .3s ease-in-out;
    position: relative;
}
.theme-card img:hover {
    filter: blur(2px);
    cursor:pointer;
}
.theme-card svg.feather-icon {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 2;
  cursor:pointer;
}
.deleteDiv {
    position: absolute;
    max-width: 50%;
    top: 0;
    right: 0;
    text-align: center;
    padding: 5px;
    box-shadow: -3px 5px 8px 2px rgb(0 0 0 / 60%);
    font-weight: bold;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 5px;
    cursor: pointer;
    z-index: 2;
}

.dropBtn {
    background-color: #d3394c;
    border: 0;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    padding: 15px 35px;
    position: relative;
}

.dropBtn:hover {
    background-color: #722040;
}

input[type="file"] {
    position: absolute;
    opacity: 0;
    z-index: -1;
}

.align-center {
    text-align: center;
}

.hidden {
    display: none !important;
}

.hidden.image {
    display: inline-block !important;
}

.display-inline {
    display: inline-block;
    vertical-align: middle;
}

.img {
    border: 1px solid #f6f6f6;
    display: inline-block;
    width: 400px;
    height: 250px;
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
    padding: 0 10px;
}

.drop {
    background-color: #f2f2f2;
    border: 4px dashed #ccc;
    background-color: #f6f6f6;
    border-radius: 2px;
    height: 400px;
    width: 600px;
    max-width: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imageBox {
    max-width: 600px;
}
.text-break {
    word-break: break-all;
}
</style>

<style lang="sass">
#mainCarousel
  .carousel-control-prev-icon,
  .carousel-control-next-icon
    background-size: 100%, 100%
    background-color: #3f3f3f
    box-shadow: -3px 5px 8px 2px rgb(0 0 0 / 40%)
    border-radius: 50%
    height: 12%
    width: 100%
  .carousel-control-prev,
  .carousel-control-next
    width: 2% !important
    opacity: 1
    margin: 0px 4px
  carousel-control-prev,
  carousel-control-next
    background: gray !important
  .carousel-indicators li
    background-color: transparent
    border-radius: 30%
    height: 6px
    margin: 0px 10px -50px 0
    background-color: lightgray
    width: 1%
    border-radius: 50%
  .carousel-indicators li.active
    background-color: #3f8d85
</style>
